.uppercase {
  text-transform: uppercase;
}

.w200 {
  font-weight: 200 !important;
}

.w300 {
  font-weight: 300 !important;
}

.w400 {
  font-weight: 400 !important;
}

.w500 {
  font-weight: 500 !important;
}

.w600 {
  font-weight: 600 !important;
}

.w700 {
  font-weight: 700 !important;
}

.d0 {
  animation-delay: 0ms;
}

.d100 {
  animation-delay: 100ms;
}

.d200 {
  animation-delay: 200ms;
}

.d300 {
  animation-delay: 300ms;
}

.d400 {
  animation-delay: 400ms;
}

.d500 {
  animation-delay: 500ms;
}

.d600 {
  animation-delay: 600ms;
}

.d700 {
  animation-delay: 600ms;
}

.d800 {
  animation-delay: 600ms;
}

.d900 {
  animation-delay: 900ms;
}

.d1000 {
  animation-delay: 1000ms;
}

.d1100 {
  animation-delay: 1100ms;
}

.d1200 {
  animation-delay: 1200ms;
}

.d1300 {
  animation-delay: 1300ms;
}

.d1400 {
  animation-delay: 1400ms;
}

.d1500 {
  animation-delay: 1500ms;
}

.d1600 {
  animation-delay: 1600ms;
}

.duration300 {
  animation-duration: 300ms;
}

.duration400 {
  animation-duration: 400ms;
}

.duration500 {
  animation-duration: 500ms;
}

.duration700 {
  animation-duration: 700ms;
}

.duration1000 {
  animation-duration: 1000ms;
}

.fg-white {
  color: white !important;
}

.fg-primary {
  color: #263238;
}

.fg-secondary {
  color: #78909c;
}

.is-primary > .fg-secondary {
  color: #b0bec5;
}

.title.is-1.is-bigger {
  font-size: 3.5rem;
}

.subtitle.is-1.is-bigger {
  font-size: 3.5rem;
}

.title.is-1.is-double {
  font-size: 6rem;
}

.subtitle.is-1.is-double {
  font-size: 6rem;
}

.title em,
.title span,
.subtitle em,
.subtitle span {
  font-weight: inherit;
}

.separator {
  width: 100%;
  height: 0.4rem;
  background-color: #90a4ae;
}

.separator.is-2 {
  height: 0.2rem;
}

.separator-2-px {
  width: 120px;
  border-bottom: 2px solid #cfd8dc;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.is-marginless-right {
  margin-right: 0 !important;
}

.is-marginless-left {
  margin-left: 0 !important;
}

.is-marginless-bottom {
  margin-bottom: 0 !important;
}

.is-marginless-top {
  margin-top: 0 !important;
}

.is-paddingless-right {
  padding-right: 0 !important;
}

.is-paddingless-left {
  padding-left: 0 !important;
}

.is-paddingless-bottom {
  padding-bottom: 0 !important;
}

.is-paddingless-top {
  padding-top: 0 !important;
}

.is-borderless-right {
  border-right: 0 !important;
}

.is-borderless-left {
  border-left: 0 !important;
}

.is-borderless-bottom {
  border-bottom: 0 !important;
}

.is-borderless-top {
  border-top: 0 !important;
}

.flex-align-start {
  align-items: flex-start;
}

.is-fullwidth {
  width: 100%;
}

.blinking {
  animation: blinker 1.5s linear infinite;
}

.text-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
