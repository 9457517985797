@font-face {
  font-family: "agile-factory";
  src: url("../font/agile-factory.eot?50181907");
  src: url("../font/agile-factory.eot?50181907#iefix")
      format("embedded-opentype"),
    url("../font/agile-factory.woff2?50181907") format("woff2"),
    url("../font/agile-factory.woff?50181907") format("woff"),
    url("../font/agile-factory.ttf?50181907") format("truetype"),
    url("../font/agile-factory.svg?50181907#agile-factory") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'agile-factory';
    src: url('../font/agile-factory.svg?50181907#agile-factory') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "agile-factory";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-st-steps-icn:before {
  content: "\e800";
} /* '' */
.icon-giphy-edit:before {
  content: "\e801";
} /* '' */
.icon-arrow-left-small-icn:before {
  content: "\e802";
} /* '' */
.icon-arrow-right-small-icn:before {
  content: "\e803";
} /* '' */
.icon-trash:before {
  content: "\e804";
} /* '' */
.icon-doc-text:before {
  content: "\e805";
} /* '' */
.icon-giphy-save:before {
  content: "\e806";
} /* '' */
.icon-giphy-square:before {
  content: "\e807";
} /* '' */
.icon-giphy-delete-white:before {
  content: "\e808";
} /* '' */
.icon-giphy-money:before {
  content: "\e809";
} /* '' */
.icon-giphy-measurement-liquid:before {
  content: "\e80a";
} /* '' */
.icon-giphy-measurement-units:before {
  content: "\e80b";
} /* '' */
.icon-giphy-measurement-length:before {
  content: "\1F4CF";
} /* '📏' */
.icon-giphy-measurement-length-double:before {
  content: "\1F4D0";
} /* '📐' */
.icon-giphy-measurement-pair:before {
  content: "\e80b";
} /* '' */
.icon-giphy-measurement-kit:before {
  content: "\e80b";
} /* '' */
.icon-giphy-external:before {
  content: "\e80c";
} /* '' */
.icon-giphy-rack:before {
  content: "\e80d";
} /* '' */
.icon-giphy-show:before {
  content: "\e80e";
} /* '' */
.icon-giphy-time:before {
  content: "\e80f";
} /* '' */
.icon-giphy-check-white:before {
  content: "\e810";
} /* '' */
.icon-giphy-alert-white:before {
  content: "\e811";
} /* '' */
.icon-giphy-steps:before {
  content: "\e812";
} /* '' */
.icon-arrow-plus-small-icn:before {
  content: "\e813";
} /* '' */
.icon-giphy-search:before {
  content: "\e814";
} /* '' */
.icon-info:before {
  content: "\e815";
} /* '' */
.icon-giphy-measurement-weight:before {
  content: "\e816";
} /* '' */
.icon-giphy-filter:before {
  content: "\e817";
} /* '' */
.icon-drag-icn:before {
  content: "\e818";
} /* '' */
.icon-pie-chart:before {
  content: "\e819";
} /* '' */
.icon-arrow-close-small-icn:before {
  content: "\e81a";
} /* '' */
.icon-giphy-delete:before {
  content: "\e81b";
} /* '' */
.icon-reload:before {
  content: "\e81c";
} /* '' */
.icon-product:before {
  content: "\e81d";
} /* '' */
.icon-fv-grid-icn:before {
  content: "\e81e";
} /* '' */
.icon-fv-jobs-icn:before {
  content: "\e81f";
} /* '' */
.icon-fv-operators-icn:before {
  content: "\e820";
} /* '' */
.icon-sc-board-icn:before {
  content: "\e821";
} /* '' */
.icon-sc-catalog-icn:before {
  content: "\e822";
} /* '' */
.icon-sc-customers-icn:before {
  content: "\e823";
} /* '' */
.icon-sc-templates-icn:before {
  content: "\e824";
} /* '' */
.icon-reset-icn:before {
  content: "\e825";
} /* '' */
.icon-st-areas-icn:before {
  content: "\e826";
} /* '' */
.icon-st-factories-icn:before {
  content: "\e827";
} /* '' */
.icon-st-machines-icn:before {
  content: "\e828";
} /* '' */
.icon-st-stations-icn:before {
  content: "\e829";
} /* '' */
.icon-st-users-icn:before {
  content: "\e82a";
} /* '' */
.icon-st-workers-icn:before {
  content: "\e82b";
} /* '' */
.icon-st-workgroups-icn:before {
  content: "\e82c";
} /* '' */
.icon-st-general-icn:before {
  content: "\e82d";
} /* '' */
.icon-sb-dashboard-icn:before {
  content: "\e82e";
} /* '' */
.icon-st-processes-icn:before {
  content: "\e82f";
} /* '' */
.icon-sb-factory-icn:before {
  content: "\e830";
} /* '' */
.icon-sb-job-board-icn:before {
  content: "\e831";
} /* '' */
.icon-sb-settings-icn:before {
  content: "\e832";
} /* '' */
.icon-sb-signout-icn:before {
  content: "\e833";
} /* '' */
.icon-st-safety-icn:before {
  content: "\e834";
} /* '' */
.icon-st-configuration-icn:before {
  content: "\e835";
} /* '' */
.icon-giphy-upload:before {
  content: "\e836";
} /* '' */
.icon-giphy-lock:before {
  content: "\e837";
} /* '' */
.icon-more-icn:before {
  content: "\e838";
} /* '' */
.icon-menu-icn:before {
  content: "\e839";
} /* '' */
.icon-st-hmi-icn:before {
  content: "\e83a";
} /* '' */
.icon-sc-priority-icn:before {
  content: "\e83b";
} /* '' */
.icon-sc-gantt-icn:before {
  content: "\e83c";
} /* '' */
.icon-sc-suppliers-icn:before {
  content: "\e83d";
} /* '' */
.icon-st-about-icn:before {
  content: "\e83e";
} /* '' */
.icon-arrow-down-small-icn:before {
  content: "\e83f";
} /* '' */
.icon-arrow-up-small-icn:before {
  content: "\e840";
} /* '' */
.icon-giphy-calendar:before {
  content: "\e841";
} /* '' */
.icon-sc-jobs-draft-icn:before {
  content: "\e842";
} /* '' */
.icon-sc-jobs-done-icn:before {
  content: "\e843";
} /* '' */
.icon-sc-jobs-queue-icn:before {
  content: "\e844";
} /* '' */
.icon-capacity:before {
  content: "\e845";
} /* '' */
.icon-download-icn:before {
  content: "\e846";
} /* '' */
.icon-sb-planning-icn:before {
  content: "\e847";
} /* '' */
.icon-giphy-split:before {
  content: "\e848";
} /* '' */
.icon-pdf:before {
  content: "\e849";
} /* '' */
.icon-status-approval:before {
  content: "\e84a";
} /* '' */
.icon-sb-stock-icn:before {
  content: "\e84b";
} /* '' */
.icon-status-check:before {
  content: "\e84c";
} /* '' */
.icon-status-material:before {
  content: "\e84d";
} /* '' */
.icon-status-production:before {
  content: "\e84e";
} /* '' */
.icon-status-setup:before {
  content: "\e84f";
} /* '' */
.icon-st-paths-icn:before {
  content: "\e850";
} /* '' */
.icon-st-programs-icn:before {
  content: "\e851";
} /* '' */
.icon-excel:before {
  content: "\e852";
} /* '' */
.icon-sc-containers-icn:before {
  content: "\e853";
} /* '' */
.icon-attachment:before {
  content: "\e854";
} /* '' */
.icon-st-containers-icn:before {
  content: "\e855";
} /* '' */
.icon-sc-logs-icn:before {
  content: "\e856";
} /* '' */
.icon-swap-icn:before {
  content: "\e857";
} /* '' */
.icon-print:before {
  content: "\e858";
} /* '' */
.icon-emo-saint:before {
  content: "\e859";
} /* '' */
.icon-light:before {
  content: "\e85a";
} /* '' */
.icon-unlock:before {
  content: "\e85b";
} /* '' */
.icon-connect:before {
  content: "\e85c";
} /* '' */
.icon-barcode-delete:before {
  content: "\e85d";
} /* '' */
.icon-star-empty:before {
  content: "\e85e";
} /* '' */
.icon-award:before {
  content: "\e85f";
} /* '' */
.icon-barcode:before {
  content: "\e900";
} /* '' */
.icon-camera:before {
  content: "\e901";
} /* '' */
